import React from "react";
import { string, object } from "prop-types";
import { Link } from "gatsby";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import Image from "../../../components/ImageQuerys/HeroImages";
import RandomImage from "../../../components/ImageQuerys/RandomImages";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import ogImage from "../../../images/global_assets/become_an_ally.png";
import casualDiningIcon from "../../../images/icons/casual-dining.png";
import barsIcon from "../../../images/icons/Bars-and-nightclubs.png";
import foodIcon from "../../../images/icons/food-trucks.png";
import quickServiceIcon from "../../../images/icons/qsr.png";
import retailIcon from "../../../images/icons/home-decor.png";
import autoIcon from "../../../images/icons/auto-parts.png";
import salonsIcon from "../../../images/icons/hair-salon.png";
import boutiquesIcon from "../../../images/icons/clothing-icon.png";
import backgroundSmallV2 from "../../../images/svg/vector-04.svg";
import AllyForm from "../../../components-v2/Forms/AllyForm";

const Card = ({ key, title, description, icon }) => {
  return (
    <figure
      key={key}
      className="rounded-[24px] p-6"
      style={{ border: "1px solid #E4E6E9" }}
    >
      <img className="max-w-[32px]" src={icon} alt="icon" />
      <div className="text-black mt-2 text-[24px] font-bold">{title}</div>
      <p className="text-p text-black-300 mt-2">{description}</p>
    </figure>
  );
};

Card.propTypes = {
  key: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  icon: object.isRequired,
};

const cards = [
  {
    key: "Casual dining",
    title: "Casual dining",
    description: "POS, online ordering, and more for full-service restaurants",
    icon: casualDiningIcon,
  },
  {
    key: "Bars & breweries",
    title: "Bars & breweries",
    description: "POS, handhelds, QR ordering for bars & breweries",
    icon: barsIcon,
  },
  // {
  //   key: "Food Trucks",
  //   title: "Food Trucks",
  //   description: "Mobile POS and online ordering for mobile kitchens",
  //   icon: foodIcon,
  // },
  {
    key: "Quick service",
    title: "Quick service",
    description: "Counter-service POS, QR, online ordering, and more\n",
    icon: quickServiceIcon,
  },
  {
    key: "Retail",
    title: "Retail",
    description: "Omnichannel POS to sell better in store and online",
    icon: retailIcon,
  },
  {
    key: "Automotive",
    title: "Automotive",
    description: "Payments, appointments, marketing, and more",
    icon: autoIcon,
  },
  {
    key: "Salons",
    title: "Salons",
    description: "Online appointments, scheduling, and integrated POS",
    icon: salonsIcon,
  },
  {
    key: "Boutiques",
    title: "Boutiques",
    description: "Easy-to-use POS, ecommerce, and shipping integrations",
    icon: boutiquesIcon,
  },
];

const BecomeAnAlly = () => {
  return (
    <Layout
      className="non-fixed-width become-ally"
      transparentHeader
      isLanding
      useNewLPHeader
    >
      <SEO
        title="Become An Ally | SpotOn"
        description=""
        image={`https://spoton.com/${ogImage}`}
      />

      <Section
        fullWidth
        animations={false}
        className="bg-black pt-20 md:pt-32 lg:pt-40"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 2xl:gap-20 ">
          <div className="">
            <Heading
              withBlueSymbol
              className="text-white"
              level={1}
              injectionType={1}
            >
              Join the SpotOn Partner & Referral Program
            </Heading>
            <p className="text-black-500 text-[20px] mt-8">
              Earn bonuses and help local businesses succeed with best-in-class
              point-of-sale technology and software.
              <br />
              <a
                href="/partners/become-an-ally/ally-terms"
                target="_blank"
                className="text-black-500 underline hover:text-black-500 hover:underline"
              >
                Terms and conditions apply.
              </a>
            </p>

            <CtaPrimary
              className="mt-12"
              title="Become a Partner"
              target="#form"
            />
          </div>

          <div className="w-full">
            <Image className="" imageName="become-an-ally_1.png" />
          </div>
        </div>
      </Section>

      <Section fullWidth className="bg-black">
        <div className="py-3 bg-black grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 justify-between justify-items-center">
          <figure
            className="max-w-[256px]"
            style={{ borderTop: "2px solid #445469" }}
          >
            <div className="text-primary text-[14px] mt-5">Step 1</div>
            <div className="text-white text-[24px] mt-3">
              Apply to join our Partner program
            </div>
            <p className="text-p text-black-500 mt-10">
              Fill out the form on this page and our team will follow up to
              schedule a call.
            </p>
          </figure>

          <figure
            className="max-w-[256px]"
            style={{ borderTop: "2px solid #445469" }}
          >
            <div className="text-primary text-[14px] mt-5">Step 2</div>
            <div className="text-white text-[24px] mt-3">
              Meet the SpotOn Partnership Team
            </div>
            <p className="text-p text-black-500 mt-10">
              We’ll meet to discuss how we can help you earn extra income
              through referring businesses to SpotOn.
            </p>
          </figure>

          <figure
            className="max-w-[256px]"
            style={{ borderTop: "2px solid #445469" }}
          >
            <div className="text-primary text-[14px] mt-5">Step 3</div>
            <div className="text-white text-[24px] mt-3">
              Activate the Partnership
            </div>
            <p className="text-p text-black-500 mt-10">
              Sign our referral agreement and receive a link to the partnership
              referral page.
            </p>
          </figure>

          <figure
            className="max-w-[256px]"
            style={{ borderTop: "2px solid #445469" }}
          >
            <div className="text-primary text-[14px] mt-5">Step 4</div>
            <div className="text-white text-[24px] mt-3">
              Refer businesses & get paid
            </div>
            <p className="text-p text-black-500 mt-10">
              Introduce your network to SpotOn’s solutions and get paid for
              accounts that go live.
            </p>
          </figure>
        </div>
      </Section>

      <Section className="mt-20">
        <Heading
          level={2}
          injectionType={2}
          className="md:max-w-[490px]"
          withBlueSymbol
        >
          Earn referral bonuses for restaurant, retail, and other local
          businesses
        </Heading>

        <div
          className={`
          mt-10
          grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
          gap-8
        `}
        >
          {cards.map(Card)}
        </div>
      </Section>

      <Section fullWidth fullWidthCarousel className="">
        <div className="relative w-full max-w-[1360px] mx-auto bg-black xl:rounded-[32px] py-16 lg:py-32">
          <img
            className={`
          absolute
          right-0
          top-0
        `}
            alt="background"
            src={backgroundSmallV2}
          />

          <Heading
            level={2}
            injectionType={1}
            withBlueSymbol
            className="text-white text-center relative"
          >
            Partner type
          </Heading>

          <CtaPrimary
            className="mt-12 mx-auto relative"
            title="Become an Ally"
            target="#form"
          />

          <div className="flex flex-col md:flex-row justify-center items-start mt-20 gap-y-10 md:gap-x-[50px] xl:gap-x-[100px] px-10">
            <div className="max-w-[405px]">
              <Heading
                level={3}
                injectionType={3}
                className="text-white"
                withBlueSymbol
              >
                Ally Partner
              </Heading>
              <p className="text-black-700 text-p mt-6">
                Ally Partner. Restaurant consultants, accountants, financial
                advisors, networking group leaders, marketing agencies, real
                estate agents, IT networking companies, telecommunications sales
                people, food and beverage sales reps.
              </p>
            </div>
            <div className="hidden md:block h-full min-h-[200px] w-[1px] bg-black-200 self-stretch opacity-50" />
            <div className="max-w-[405px]">
              <Heading
                level={3}
                injectionType={3}
                className="text-white"
                withBlueSymbol
              >
                Strategic Partner
              </Heading>
              <p className="text-black-700 text-p mt-6">
                Restaurant associations, chambers of commerce, foodservice
                distributors, integration partner and software companies that
                integrate with POS.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section
        fullWidth
        fullWidthCarousel
        className="mt-20 lg:mt-32 mb-20 lg:mb-32"
      >
        <div className="squatchembed" />
        <Heading
          level={2}
          injectionType={2}
          withBlueSymbol
          className="text-center"
        >
          Become a Partner
        </Heading>
        <p className="text-black-300 text-p-large mt-6 text-center max-w-[1074px] mx-auto px-4 md:px-10">
          Help local restaurants and small businesses within your network
          overcome today’s challenges with a technology partner who cares. Just
          complete the form below.
        </p>

        <div className="relative w-full min-h-[300px]" id="form">
          <Section className="relative z-1">
            <div
              className="bg-white rounded-[24px] max-w-[850px] mx-auto p-4 md:p-14"
              style={{
                border: "1px solid #E4E6E9",
                boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)",
              }}
            >
              <AllyForm className="" />
            </div>
          </Section>
          <RandomImage
            imageName="background-1.png"
            className="ally-background-image absolute top-20 left-0 right-0 bottom-0"
          />
        </div>
      </Section>

      <style jsx global>{`
        .ally-background-image {
          position: absolute !important;
        }

        .ally-background-image img {
          object-fit: fill !important;
        }
        .become-ally {
          scroll-behavior: smooth;
        }
      `}</style>
    </Layout>
  );
};

export default BecomeAnAlly;

import React, { useState } from "react";
import { string } from "prop-types";
import { navigate } from "gatsby";
import { Form, Input, Select, Checkbox } from "antd";
import {
  errorStyles,
  labelStyles,
} from "../../../page-components/affiliates/form";
import CtaPrimary from "../../Base/CtaPrimary";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  zipValidationRegExp,
} from "../../../constants";
import { register } from "../../../utils/firebase/ally-partners";

const { Option } = Select;

const hasLength = (value) => {
  return value.trim().length > 0;
};

const AllyForm = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [company, setCompany] = useState("");
  const [wantToBe, setWantToBe] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [utmSource, setUTMSource] = useState("");
  const [utmMedium, setUTMMedium] = useState("");

  const checkErrors = async () => {
    const err = {
      firstName: !hasLength(firstName),
      lastName: !hasLength(lastName),
      email: !emailValidationRegExp.test(email),
      phone: !phoneValidationRegExp.test(phone),
      zip: !zipValidationRegExp.test(zip),
      company: !hasLength(company),
      wantToBe: !hasLength(wantToBe),
      termsAccepted: !termsAccepted,
    };
    setErrors(err);

    return Object.keys(err)
      .map((key) => err[key])
      .includes(true);
  };

  const onSubmit = async () => {
    const hasErrors = await checkErrors();
    if (hasErrors) {
      setShowErrors(true);
      return;
    }

    // const address = zipCodesMap.get(zip);
    const data = {
      firstName,
      lastName,
      email: email.trim().toLowerCase(),
      phone,
      // city: address.city,
      // state: address.state,
      zip,
      company,
      wantToBe,
      // submittedOn,
      utmSource,
      utmMedium,
    };
    setLoading(true);
    const submitted = await register(email.trim().toLowerCase(), data);
    setLoading(false);

    if (submitted) {
      setShowSubmitError(false);
      navigate("/partners/become-an-ally/thank-you");
    } else {
      setShowSubmitError(true);
    }
  };

  return (
    <div
      className={`
        ${className}
      `}
    >
      <div
        className={`
        grid grid-cols-1 md:grid-cols-2
        gap-x-4 lg:gap-x-8
        gap-y-1
      `}
      >
        <div className="">
          <div className={labelStyles}>First Name</div>
          <Input
            value={firstName}
            onChange={({ target: { value } }) => setFirstName(value)}
            placeholder="E.g. John"
            size="large"
            style={{
              borderColor: showErrors && errors.firstName ? "#F73E3C" : "",
            }}
          />
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.firstName ? 100 : 0
            }`}
          >
            Please enter first name
          </div>
        </div>

        <div>
          <div className={labelStyles}>Last Name</div>
          <Input
            value={lastName}
            onChange={({ target: { value } }) => setLastName(value)}
            placeholder="E.g. Doe"
            size="large"
            style={{
              borderColor: showErrors && errors.lastName ? "#F73E3C" : "",
            }}
          />
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.lastName ? 100 : 0
            }`}
          >
            Please enter last name
          </div>
        </div>

        <div>
          <div className={labelStyles}>Email</div>
          <Input
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            placeholder="E.g. johndoe@mail.com"
            size="large"
            style={{
              borderColor: showErrors && errors.email ? "#F73E3C" : "",
            }}
          />
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.email ? 100 : 0
            }`}
          >
            Please enter a valid email
          </div>
        </div>

        <div>
          <div className={labelStyles}>Phone Number</div>
          <Input
            value={phone}
            onChange={({ target: { value } }) => setPhone(value)}
            placeholder="E.g. 111 222 3333"
            size="large"
            style={{
              borderColor: showErrors && errors.phone ? "#F73E3C" : "",
            }}
          />
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.phone ? 100 : 0
            }`}
          >
            Please enter a valid phone
          </div>
        </div>

        <div>
          <div className={labelStyles}>Zip Code</div>
          <Input
            value={zip}
            onChange={({ target: { value } }) => setZip(value)}
            placeholder="E.g. 10001"
            size="large"
            style={{
              borderColor: showErrors && errors.zip ? "#F73E3C" : "",
            }}
          />
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.zip ? 100 : 0
            }`}
          >
            Please enter your zip code
          </div>
        </div>

        <div>
          <div className={labelStyles}>Company Name</div>
          <Input
            value={company}
            onChange={({ target: { value } }) => setCompany(value)}
            placeholder="E.g. Vista Restaurants"
            size="large"
            style={{
              borderColor: showErrors && errors.company ? "#F73E3C" : "",
            }}
          />
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.company ? 100 : 0
            }`}
          >
            Please enter your company name
          </div>
        </div>

        <div className="w-full md:col-span-2">
          <div className={labelStyles}>Partner Type</div>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a state",
              },
            ]}
            validateStatus={showErrors && errors.partnerType ? "error" : ""}
          >
            <Select
              className="w-full"
              defaultValue={wantToBe}
              onChange={() => {
                setWantToBe("Ally Partner (Affiliate)");
                setUTMSource("Ally Partner (Affiliate)");
                setUTMMedium(company);
              }}
              style={{
                borderColor: showErrors && errors.wantToBe ? "#F73E3C" : "",
              }}
            >
              <Option value="" />
              <Option value="Ally Partner (Affiliate)">
                Ally Partner (Affiliate)
              </Option>
              <Option value="Integration Partner">Integration Partner</Option>
              <Option value="Strategic Partner">Strategic Partner</Option>
              <Option value="Broker Partner">Broker</Option>
            </Select>
            <div
              className={`${errorStyles} opacity-${
                showErrors && errors.wantToBe ? 100 : 0
              }`}
            >
              Please select an option
            </div>
          </Form.Item>
        </div>
      </div>

      <div className="w-full md:col-span-2">
        {/* <div className={labelStyles}>I wanna be</div> */}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please accept the terms and conditions",
            },
          ]}
          validateStatus={showErrors && errors.termsAccepted ? "error" : ""}
        >
          <Checkbox onChange={(e) => setTermsAccepted(e.target.checked)}>
            I accept the{" "}
            <a
              href="/partners/become-an-ally/ally-terms"
              className="text-primary"
              target="_blank"
            >
              terms and conditions.
            </a>
          </Checkbox>
          <div
            className={`${errorStyles} opacity-${
              showErrors && errors.termsAccepted ? 100 : 0
            }`}
          >
            Please accept the terms and conditions
          </div>
        </Form.Item>
      </div>

      <CtaPrimary
        disabled={loading}
        className="mx-auto"
        title="Submit"
        onClick={onSubmit}
      />
      <div
        className={`${errorStyles} opacity-${
          showSubmitError ? 100 : 0
        } text-center`}
      >
        {`We couldn't process the form. Please try again later.`}
      </div>
    </div>
  );
};

AllyForm.propTypes = {
  className: string,
};

AllyForm.defaultProps = {
  className: "",
};

export default AllyForm;

import { doc, setDoc, getDoc } from "firebase/firestore";
import { signAnonymously, db } from "./firestore";
import uid from "../uid";
import { getSubmittedOnAndConversionId } from "../form-utils";

const MEMBERS_COLLECTION = "ally_members";
const REFERRALS_COLLECTION = "ally_referrals";

export const register = async (email, data = {}) => {
  try {
    await signAnonymously().then(() => {
      const id = uid();
      return setDoc(
        doc(db, MEMBERS_COLLECTION, id),
        { ally_id: id, ...data, ...getSubmittedOnAndConversionId() },
        {
          merge: true,
        }
      );
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getAlly = async (id) => {
  try {
    return await signAnonymously().then(async () => {
      const docSnap = await getDoc(doc(db, MEMBERS_COLLECTION, id));
      if (docSnap.exists()) {
        const data = await docSnap.data();
        // console.log("Document data:", docSnap.data());
        return data;
      }
      // doc.data() will be undefined in this case
      // console.log("No such document!");
      return null;
    });
  } catch (err) {
    // console.log(888, err);
    return null;
  }
};

export const submitALead = async (allyId, data = {}) => {
  const id = uid();
  try {
    await signAnonymously().then(() => {
      return setDoc(
        doc(db, REFERRALS_COLLECTION, id),
        { ...data, ...getSubmittedOnAndConversionId() },
        {
          merge: true,
        }
      );
    });
    return true;
  } catch (err) {
    console.log(111, err);
    return false;
  }
};
